.miniNewsletter .form_grid {
  padding: 0 !important;
  float: left;
  width: 50%; }
  .miniNewsletter .form_grid label[for] {
    line-height: 2rem;
    padding: 0 1em;
    position: absolute;
    cursor: text; }
  .miniNewsletter .form_grid textarea,
  .miniNewsletter .form_grid input[type=text] {
    background-color: #FFF; }
  .miniNewsletter .form_grid input[name="EMAIL"] {
    display: inline-block; }
  .miniNewsletter .form_grid .submit {
    float: none;
    font-size: 0.8em;
    content: '';
    margin-left: 0.5em;
    border-radius: 0;
    background-image: url("../../../images/iconmonstr-arrow-20.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 50%; }
